import React, { Component } from 'react';
import { Section, Container } from '../common';
import { FlexGallery } from './FlexGallery';
import { FlexGalleryWrapper } from './FlexGalleryWrapper';
import PropTypes from 'prop-types';

/** Related posts component for Blog articles */
class RelatedPosts extends Component {
  render() {
    return (
      <Section>
        <Container>
          <h2>Related articles to read</h2>
          <FlexGalleryWrapper>
            {this.props.data.edges.map(({ node: blog }) => (
              <FlexGallery
                basis="150px"
                basisWide="150px"
                title={blog.title}
                fluid={blog.featuredImage.fluid}
                key={blog.id}
                url={`/${blog.destination.slug}/${blog.slug}/`}
                alt={blog.alt}
                id={blog.id}
              />
            ))}
          </FlexGalleryWrapper>
        </Container>
      </Section>
    );
  }
}

export default RelatedPosts;

RelatedPosts.propTypes = {
  /** Data for related posts */
  data: PropTypes.object.isRequired
};
