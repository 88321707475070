import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Article, Header, Share } from '../components/layout/index.js';
import EmbedContainer from 'react-oembed-container';
import RelatedPosts from '../components/layout/RelatedPosts.js';

const BodyContainer = styled.div`
  display: grid;
  max-width: 1000px;
  margin: 2rem auto;
  grid-template-columns: 3fr 12fr 3fr;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 1rem;
    margin: 0;
  }
`;

const Figure = styled.figure`
  max-width: 900px;
  margin: 0 auto;
  figcaption {
    margin-top: 0.7rem;
    font-size: 0.8rem;
    font-style: italic;
  }
`;

const Attribution = styled.p`
  font-size: 0.7rem;
`;

export default class BlogPost extends React.Component {
  render() {
    const { data } = this.props;
    const {
      featuredImage,
      title,
      slug,
      destination,
      seoMetaTags,
      author,
      body,
      menuDark,
      meta
    } = data.datoCmsBlog;
    const rootUrl = `${data.site.siteMetadata.siteUrl}`;
    const pagePath = `/${destination.slug}/${slug}`;
    const postURL = rootUrl + pagePath;
    const schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: postURL,
        image: `${featuredImage.url}`,
        headline: `${title}`,
        author: `${author.name}`,
        datePublished: `${meta.updatedAt}`,
        logo: `https://www.stayatbase.com${data.logo.publicURL}`,
        publisher: {
          '@type': 'Organization',
          name: 'Base Backpackers'
        }
      }
    ];

    return (
      <>
        <Header
          backgroundImage={featuredImage.fluid}
          alt={featuredImage.alt}
          poster={featuredImage.url}
          pageTitle={title}
          tagline={`Written by: ${author.name}`}
          darken="true"
          caption={featuredImage.title}
          menuDark={menuDark}
          // propertyName="Base Backpackers Sydney"
          // caption="Crazy Party Tuesdays - Scary Canary Bar "
          // alt={data.datoCmsHostel.featuredImage.alt}
          // gal={cominedGallery}
          // button="hostel"
        />
        <Article>
          <HelmetDatoCms seo={seoMetaTags} />
          <Helmet>
            {/* Schema.org tags */}
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>
          </Helmet>

          <BodyContainer className="article">
            <Share
              url={this.props.location.href}
              title={title}
              image={featuredImage.url}
            />
            {body.map(block => (
              <div key={block.id} className={block.model.apiKey}>
                {block.model.apiKey === 'text' && (
                  <EmbedContainer markup={block.text}>
                    <div dangerouslySetInnerHTML={{ __html: block.text }} />
                  </EmbedContainer>
                )}
                {block.model.apiKey === 'image_block' && (
                  <Figure>
                    <Img
                      alt={block.image.alt}
                      fluid={block.image.fluid}
                      loading="lazy"
                    />
                    <figcaption>{block.caption}</figcaption>
                    <Attribution
                      dangerouslySetInnerHTML={{ __html: block.attribution }}
                    />
                  </Figure>
                )}
              </div>
            ))}
            <Share
              url={this.props.location.href}
              title={title}
              image={featuredImage.url}
              cta={'Like this post? Please share it'}
            />
          </BodyContainer>
          <RelatedPosts data={data.allDatoCmsBlog} />
        </Article>
      </>
    );
  }
}

export const query = graphql`
  query($slug: String!, $destination: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    datoCmsBlog(slug: { eq: $slug }) {
      title
      slug
      menuDark
      meta {
        updatedAt
      }
      destination {
        slug
        title
      }
      author {
        name
      }
      featuredImage {
        url
        title
        alt
        fluid(
          maxWidth: 2000
          maxHeight: 800
          imgixParams: {
            fm: "jpg"
            auto: "compress"
            fit: "crop"
            crop: "faces"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      body {
        ... on DatoCmsText {
          id
          model {
            apiKey
          }
          text
        }
        ... on DatoCmsImageBlock {
          id
          model {
            apiKey
          }
          caption
          attribution
          image {
            alt
            fluid(
              maxWidth: 800

              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    allDatoCmsBlog(
      limit: 8
      filter: {
        destination: { title: { eq: $destination } }
        locale: { eq: "en" }
      }
    ) {
      edges {
        node {
          title
          id
          slug
          destination {
            title
            slug
          }
          featuredImage {
            url
            title
            alt
            fluid(
              maxWidth: 400
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
